<div class="card patient-card">
  <div class="card-content">
    <div class="card-content__avatar">
      @if (patientAvatarUrl) {
        <img [src]="patientAvatarUrl" [alt]="patientName" />
      } @else {
        <span>{{ patientName | shortName }}</span>
      }
    </div>
    <div class="card-content__info">
      <h4 class="card-content__name">{{ patientName }}</h4>
      <p class="card-content__room">{{ patientRoom }}</p>
    </div>
  </div>
  <div class="card-alerts">
    <div class="card-alerts__icon">
      <lib-icon
        [iconName]="patientAlertsSubject | alertSubjectIcon"
        [severity]="severity" />
      <nz-badge
        nzStandalone
        [nzOffset]="[0, 0]"
        [nzCount]="patientAlerts > 1 ? patientAlerts - 1 : 0"></nz-badge>
    </div>
  </div>
</div>
