<nz-layout class="layout">
  <nz-sider class="nz-slider" nzTheme="light" nzWidth="96px" nzNoAnimation>
    <lib-side-menu
      [items]="sidenavItems"
      [user]="sidebarUser"
      [isOpened]="isOpenedMenu"
      [popoverVisible]="popoverVisible"
      [nzPopoverContent]="popover"
      (listItemClick)="isOpenedMenu = false; itemClick($event)"
      (isOpenedMenuChange)="isOpenedMenu = false" />
  </nz-sider>
  <nz-layout class="right-layout" [style.backgroundColor]="tableBackground">
    <nz-header>
      <lib-header
        [isOpened]="isOpenedMenu"
        (toggleSideMenu)="isOpenedMenu = !isOpenedMenu"
        [pageTitle]="title"
        [alertsItems]="alertListItems"
        [searchPatients]="headerSearchPatients" />
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <ng-content />
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
<ng-template #popover>
  <div>text</div>
</ng-template>
