export enum AlertSubject {
  BodyTemperature = 'body_temperature',
  Device = 'device',
  DeviceBattery = 'device_battery',
  General = 'general',
  HeartRate = 'heart_rate',
  Posture = 'posture',
  RespirationRate = 'respiration_rate',
  SystolicPressure = 'systolic_pressure',
  DiastolicPressure = 'diastolic_pressure',
  ExitBedRisk = 'exit_bed_risk',
  BloodGlucose = 'blood_glucose',
  SPO2 = 'spo2',
  Mobility = 'mobility'
}
