/*
 * Public API Surface of design-system
 */

export * from './lib/button/button.component'
export * from './lib/icon/icon.component'
export * from './lib/patients'
export * from './lib/reports-list'
export * from './lib/header'
export * from './lib/lib-notification'
export * from './lib/avatar/avatar.component'
export * from './lib/tabs/tabs.component'
export * from './lib/side-menu/side-menu.component'
export * from './lib/input-number/input-number.component'
export * from './lib/select/directives/lib-select.directive'
export * from './lib/emr-alert-item/emr-alert-item.component'
export * from './lib/header/header.component'
export * from './lib/layout-example/layout-example.component'
export * from './lib/checkbox/apply-class-to-checkbox-label.directive'
