@if (!iconOnly && iconPosition === 'right') {
  <button
    nz-button
    [disabled]="isDisabled"
    [type]="btnType"
    [nzType]="nzType"
    [nzSize]="nzSize"
    [nzGhost]="nzGhost"
    [nzBlock]="nzBlock"
    [nzDanger]="nzDanger"
    [nzLoading]="nzLoading"
    [nzShape]="nzShape"
    (click)="onHandleClick()">
    {{ label }}
    @if (hasIcon) {
      <i nz-icon [nzType]="iconType" [nzTheme]="nzTheme"></i>
    }
  </button>
}
@if (!iconOnly && iconPosition === 'left') {
  <button
    nz-button
    [disabled]="isDisabled"
    [type]="btnType"
    [nzType]="nzType"
    [nzSize]="nzSize"
    [nzGhost]="nzGhost"
    [nzBlock]="nzBlock"
    [nzDanger]="nzDanger"
    [nzLoading]="nzLoading"
    [nzShape]="nzShape"
    (click)="onHandleClick()">
    @if (hasIcon) {
      <i nz-icon [nzType]="iconType" [nzTheme]="nzTheme"></i>
    }
    {{ label }}
  </button>
}
@if (iconOnly) {
  <button
    nz-button
    [disabled]="isDisabled"
    [type]="btnType"
    [nzType]="nzType"
    [nzSize]="nzSize"
    [nzGhost]="nzGhost"
    [nzBlock]="nzBlock"
    [nzDanger]="nzDanger"
    [nzLoading]="nzLoading"
    [nzShape]="nzShape"
    (click)="onHandleClick()">
    <i nz-icon [nzType]="iconType" [nzTheme]="nzTheme"></i>
  </button>
}
