import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core'
import { NzButtonModule, NzButtonShape } from 'ng-zorro-antd/button'
import { NzIconModule } from 'ng-zorro-antd/icon'

@Component({
  selector: 'lib-button',
  imports: [NzButtonModule, NzIconModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Output() handleClick: EventEmitter<void> = new EventEmitter<void>()
  @Input() nzType: 'primary' | 'dashed' | 'default' | 'text' | 'link' =
    'default'
  @Input() label: string = 'Button'
  @Input() nzSize: 'large' | 'small' | 'default' = 'default'
  @Input() nzShape: NzButtonShape = null
  @Input() isDisabled: boolean = false
  @Input() nzGhost: boolean = false
  @Input() nzBlock: boolean = false
  @Input() nzDanger: boolean = false
  @Input() nzLoading: boolean = false
  @Input() hasIcon: boolean = false
  @Input() iconOnly: boolean = false
  @Input() iconType: string = 'star'
  @Input() nzTheme: 'fill' | 'outline' | 'twotone' = 'outline'
  @Input() iconPosition: 'left' | 'right' = 'left'
  @Input() btnType: 'button' | 'submit' | 'reset' = 'button'

  public onHandleClick(): void {
    this.handleClick.emit()
  }
}
