import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { AlertSeverity } from '../shared/enums/alert-severity.enum'

@Component({
  selector: 'lib-icon',
  imports: [NzIconModule],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  @Input() iconName: string = 'star'
  @Input() iconTheme: 'fill' | 'outline' | 'twotone' = 'fill'
  @Input() severity?: AlertSeverity
}
