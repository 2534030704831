<nz-table
  [nzData]="patients"
  [nzLoading]="isLoading"
  [nzShowPagination]="false"
  nzTableLayout="fixed">
  <thead>
    <tr>
      <th nzWidth="222px">Name</th>
      <th>Room</th>
      <th>Tasks</th>
      <th>HR</th>
      <th>RR</th>
      <th>BP (Sys/Dys)</th>
      <th>SpO2</th>
      <th>Temp</th>
      @if (activeHour.type === 'time') {
        <th nzWidth="65px">Edit</th>
      }
      @if (activeHour.type !== 'time') {
        <th nzWidth="65px">EMR</th>
      }
    </tr>
  </thead>
  <tbody>
    @for (patient of patients; track patient.id) {
      <tr
        (click)="onNavigateToPatientById(patient.id)"
        [attr.severity]="patient.maxAlertSeverity">
        <td>
          <div class="name">
            @if (!patient.avatar) {
              <nz-avatar
                nzSize="small"
                [nzText]="patient.name | shortName"></nz-avatar>
            } @else {
              <nz-avatar
                [nzSrc]="patient.avatar.signedUrl"
                nzSize="small"></nz-avatar>
            }
            <span class="name-value">{{
              patient.firstName + ' ' + patient.lastName
            }}</span>
          </div>
        </td>
        <td>
          <div>{{ patient.room }}</div>
        </td>
        <td>
          <div>{{ 4 }}</div>
        </td>
        <td>
          @if (patient.reports?.measurements?.heartRate?.value) {
            <div
              [class.alerted]="
                (patient.reports?.measurements?.heartRate)!.value
                  | medicalCriticalSign
                    : (patient.patientAlertRules
                        ? patient.patientAlertRules.heartRate
                        : patient.defaultAlertRules?.heartRate)
              ">
              <lib-icon iconName="aio:manual-vitals-indicator" />
              {{
                (patient.reports?.measurements?.heartRate)!.value | roundNumber
              }}
            </div>
          } @else {
            <div>
              <lib-icon class="empty-measurements" iconName="aio:bad-reading" />
            </div>
          }
        </td>
        <td>
          @if (patient.reports?.measurements?.respirationRate?.value) {
            <div
              [class.alerted]="
                (patient.reports?.measurements?.respirationRate)!.value
                  | medicalCriticalSign
                    : (patient.patientAlertRules
                        ? patient.patientAlertRules.respirationRate
                        : patient.defaultAlertRules?.respirationRate)
              ">
              <lib-icon iconName="aio:manual-vitals-indicator" />
              {{
                (patient.reports?.measurements?.respirationRate)!.value
                  | roundNumber
              }}
            </div>
          } @else {
            <div>
              <lib-icon class="empty-measurements" iconName="aio:bad-reading" />
            </div>
          }
        </td>
        <td>
          @if (patient.reports?.measurements?.systolicPressure?.value) {
            <div
              [class.alerted]="
                ((patient.reports?.measurements?.systolicPressure)!.value
                  | medicalCriticalSign
                    : (patient.patientAlertRules
                        ? patient.patientAlertRules.systolicPressure
                        : patient.defaultAlertRules?.systolicPressure)) ||
                ((patient.reports?.measurements?.diastolicPressure)!.value
                  | medicalCriticalSign
                    : (patient.patientAlertRules
                        ? patient.patientAlertRules.diastolicPressure
                        : patient.defaultAlertRules?.diastolicPressure))
              ">
              <lib-icon iconName="aio:manual-vitals-indicator" />
              {{
                (patient.reports?.measurements?.systolicPressure)!.value
                  | roundNumber
              }}
              @if (patient.reports?.measurements?.diastolicPressure?.value) {
                /
              }
              {{
                (patient.reports?.measurements?.diastolicPressure)!.value
                  | roundNumber
              }}
            </div>
          } @else {
            <div>
              <lib-icon class="empty-measurements" iconName="aio:bad-reading" />
            </div>
          }
        </td>
        <td>
          @if (patient.reports?.measurements?.spo2?.value) {
            <div
              [class.alerted]="
                (patient.reports?.measurements?.spo2)!.value
                  | medicalCriticalSign
                    : (patient.patientAlertRules
                        ? patient.patientAlertRules.spO2
                        : patient.defaultAlertRules?.spO2)
              ">
              <lib-icon iconName="aio:manual-vitals-indicator" />
              {{ (patient.reports?.measurements?.spo2)!.value | roundNumber }} %
            </div>
          } @else {
            <div>
              <lib-icon class="empty-measurements" iconName="aio:bad-reading" />
            </div>
          }
        </td>
        <td>
          @if (patient.reports?.measurements?.bodyTemperature?.value) {
            <div
              [class.alerted]="
                (patient.reports?.measurements?.bodyTemperature)!.value
                  | celsiusToFahrenheit
                  | medicalCriticalSign
                    : (patient.patientAlertRules
                        ? patient.patientAlertRules.bodyTemperature
                        : patient.defaultAlertRules?.bodyTemperature)
                    : 'temp'
              ">
              @if (patient.reports?.measurements?.bodyTemperature?.isManual) {
                <lib-icon iconName="aio:manual-vitals-indicator" />
              }
              {{
                (patient.reports?.measurements?.bodyTemperature)!.value
                  | celsiusToFahrenheit
              }}
              {{
                (patient.reports?.measurements?.bodyTemperature)!.value
                  | celsiusFahrenheitIcon
              }}
            </div>
          } @else {
            <div>
              <lib-icon class="empty-measurements" iconName="aio:bad-reading" />
            </div>
          }
        </td>
        <td>
          <div class="actions">
            <button (click)="onPatientEdit(patient, $event)">
              <lib-icon iconName="edit" iconTheme="outline" />
            </button>
          </div>
        </td>
      </tr>
    }
  </tbody>
</nz-table>
