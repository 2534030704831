<div class="card device-card">
  <div class="card-content">
    <div class="card-content__icon">
      <lib-icon [iconName]="deviceIcon" />
    </div>
    <div class="card-content__info">
      <h4 class="card-content__name">{{ patientName }}</h4>
      <p class="card-content__room">{{ room }}</p>
    </div>
  </div>
  <div class="card-status">
    <div class="card-status__icon">
      <lib-icon [iconName]="statusText | deviceStatusIcon" />
    </div>
    <h5 class="card-status__text">{{ statusText }}</h5>
    <h6 class="card-status__number">SN. {{ serialNumber }}</h6>
  </div>
</div>
