import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core'
import { NzTableModule } from 'ng-zorro-antd/table'
import { NzAvatarModule } from 'ng-zorro-antd/avatar'
import {
  ShortNamePipe,
  RoundNumberPipe,
  CelsiusToFahrenheitPipe
} from '../shared'
import { IconComponent } from '../icon/icon.component'
import { AlertSeverity } from '../shared/enums/alert-severity.enum'
import { MedicalCriticalSignPipe } from './pipes/medical-critical-sign.pipe'
import { CelsiusFahrenheitIconPipe } from './pipes/celsius-fahrenheit-icon.pipe'
import { Patient } from './interfaces/patient.interface'

@Component({
  selector: 'lib-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IconComponent,
    NzTableModule,
    NzAvatarModule,
    ShortNamePipe,
    MedicalCriticalSignPipe,
    RoundNumberPipe,
    CelsiusToFahrenheitPipe,
    CelsiusFahrenheitIconPipe
  ]
})
export class ReportsListComponent {
  @Output() navigateToPatientById: EventEmitter<string> =
    new EventEmitter<string>()
  @Output() patientEdit: EventEmitter<Patient> = new EventEmitter<Patient>()
  @Input({ required: true }) activeHour!: {
    active: boolean
    text: string
    type: 'time' | string
  }
  @Input() patients: Patient[] = []
  @Input() tableHeight: { y: string } = { y: 'calc(100vh - 210px)' }
  @Input() isLoading: boolean = false

  public criticalSeverity = AlertSeverity.Critical

  public onNavigateToPatientById(patientId: string): void {
    this.navigateToPatientById.emit(patientId)
  }

  public onPatientEdit(patient: Patient, event: Event): void {
    event.stopPropagation()
    this.patientEdit.emit(patient)
  }
}
