export const getUserLocale = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0]
  }
  return navigator.language
}

export const transformCelsiusToFahrenheit = (data: number | string) => {
  return (Number(data) * 9) / 5 + 32
}
