import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NzEmptyModule } from 'ng-zorro-antd/empty'
import { CriticalDevicesCardComponent } from '../critical-devices-card/critical-devices-card.component'
import { CriticalDevice } from '../interfaces/critical-device.interface'

@Component({
  selector: 'lib-critical-devices-list',
  imports: [CriticalDevicesCardComponent, NzEmptyModule],
  templateUrl: './critical-devices-list.component.html',
  styleUrls: ['./critical-devices-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CriticalDevicesListComponent {
  @Input() title: string = 'ALERTED DEVICES'
  @Input({ required: true }) criticalDevices: CriticalDevice[] = []
}
