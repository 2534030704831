import { Injectable } from '@angular/core'
import { Subject, zip } from 'rxjs'

export type LibNotificationType = 'success' | 'warning' | 'error'
export type LibNotificationOptions = {
  title: string
  duration?: number
  description?: string
}
export type LibNotificationOptionsWithType = LibNotificationOptions & {
  type: LibNotificationType
}

@Injectable({
  providedIn: 'root'
})
export class LibNotificationService {
  private notificationType$ = new Subject<LibNotificationType>()
  private notificationOptions$ = new Subject<LibNotificationOptions>()

  public notificationData$ = zip(
    this.notificationType$,
    this.notificationOptions$
  )

  public success(options: LibNotificationOptions): void {
    this.open('success', { type: 'success', ...options })
  }

  public error(options: LibNotificationOptions): void {
    this.open('error', { type: 'error', ...options })
  }

  public warning(options: LibNotificationOptions): void {
    this.open('warning', { type: 'warning', ...options })
  }

  public open(
    type: LibNotificationType,
    options: LibNotificationOptionsWithType
  ): void {
    this.notificationType$.next(type)
    this.notificationOptions$.next({ duration: 3000, ...options })
  }
}
