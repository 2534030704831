import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { IconComponent } from '../../icon/icon.component'
import { DeviceStatusIconPipe } from '../pipes/device-status-icon.pipe'
import { DeviceCriticalStatus } from '../enums/device-critical-status'

@Component({
  selector: 'lib-critical-devices-card',
  imports: [IconComponent, DeviceStatusIconPipe],
  templateUrl: './critical-devices-card.component.html',
  styleUrls: ['./critical-devices-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CriticalDevicesCardComponent {
  @Input() deviceIcon: string = 'aio:new-read'
  @Input() patientName: string = 'First Last'
  @Input() room: string = '29'
  @Input() serialNumber: string = '14337'
  @Input() statusText: DeviceCriticalStatus = DeviceCriticalStatus.NoConnection
}
