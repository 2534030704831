import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'roundNumber',
  standalone: true
})
export class RoundNumberPipe implements PipeTransform {
  transform(number: number): number | null {
    if (!number) return null
    return Math.round(number)
  }
}
