import { Pipe, PipeTransform } from '@angular/core'
import { AlertSubject } from '../enums/alert-subject.enum'

@Pipe({
  name: 'alertSubjectIcon',
  standalone: true
})
export class AlertSubjectIconPipe implements PipeTransform {
  transform(subject: string): string {
    if (!subject) return ''
    if (subject === AlertSubject.HeartRate) {
      return 'aio:cardio'
    } else if (subject === AlertSubject.DeviceBattery) {
      return 'aio:low-battery'
    } else if (subject === AlertSubject.Device) {
      return 'aio:new-read'
    } else if (subject === AlertSubject.RespirationRate) {
      return 'aio:respiration'
    } else if (subject === AlertSubject.BodyTemperature) {
      return 'aio:temperature'
    } else if (
      subject === AlertSubject.SystolicPressure ||
      subject === AlertSubject.DiastolicPressure ||
      subject === 'systolicPressure' ||
      subject === 'diastolicPressure'
    ) {
      return 'aio:blood-press'
    } else if (subject === AlertSubject.SPO2) {
      return 'aio:spo2'
    } else if (subject === AlertSubject.ExitBedRisk) {
      return 'aio:manual-vitals-indicator'
    } else if (
      subject === AlertSubject.BloodGlucose ||
      subject === 'bloodGlucose'
    ) {
      return 'aio:glocose'
    } else if (subject === AlertSubject.Mobility) {
      return 'aio:press-scor'
    }
    return 'aio:alert'
  }
}
