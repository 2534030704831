import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'celsiusFahrenheitIcon',
  standalone: true
})
export class CelsiusFahrenheitIconPipe implements PipeTransform {
  transform(data: number): string {
    if (!data) return ''
    return '°F'
    // return getUserLocale() === 'en-US' ? '°F' : '°C'
  }
}
