import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import { SideMenuComponent, ListItem } from '../side-menu/side-menu.component'
import { HeaderComponent } from '../header/header.component'
import { AlertListItem, BasePatient } from '../header/interfaces'

@Component({
  selector: 'lib-layout-example',
  imports: [NzLayoutModule, SideMenuComponent, HeaderComponent],
  templateUrl: './layout-example.component.html',
  styleUrls: ['./layout-example.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutExampleComponent {
  @Input() tableBackground: string = '#fff'
  public title = 'Patients'

  public isOpenedMenu: boolean = false
  public popoverVisible = false

  public alertListItems = [
    { key: 'alerted', value: 2 },
    { key: 'alerted-devices', value: 22 },
    { key: 'tasks', value: 34 }
  ] as AlertListItem[]

  public headerSearchPatients = [
    { id: '1', name: 'Luella Johns' },
    { id: '2', name: 'Alton Whittington' },
    { id: '3', name: 'Ilda Whittle' },
    { id: '4', name: 'Wei Echevarria' },
    { id: '5', name: 'Hillary Headley' }
  ] as BasePatient[]

  public sidenavItems = [
    { icon: 'aio:home-aiomed', title: 'Home', link: '/home' },
    { icon: 'aio:patients', title: 'Patients', link: '/patients' },
    { icon: 'aio:alert', title: 'Alerts', link: '/alerts', badgeCount: 9 },
    { icon: 'aio:insights', title: 'Insights', link: '/insights' },
    {
      icon: 'aio:messages-aiomed',
      title: 'Messages',
      link: '/messages',
      badgeCount: 9
    },
    { icon: 'aio:devices', title: 'Devices', link: '/devices' },
    { icon: 'aio:reports-menu', title: 'Reports', link: '/reports' }
  ]

  public sidebarUser = {
    name: 'Flora Miazrki',
    avatar: 'https://i.pravatar.cc/150?u=a042581f4e29026704d'
  } as any

  public itemClick(event: { item: ListItem; index: number }): void {
    this.title = event.item.title!
  }
}
