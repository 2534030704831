import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { AlertsItemComponent } from '../alerts-item/alerts-item.component'
import { AlertListItem } from '../interfaces/alert-list-item.interface'

@Component({
  selector: 'lib-alerts-list',
  imports: [AlertsItemComponent],
  templateUrl: './alerts-list.component.html',
  styleUrls: ['./alerts-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertsListComponent {
  @Input({ required: true }) alertListItems!: AlertListItem[] | null
}
