<header
  class="header"
  [class.blue-mode]="isBlueMode"
  [class.device-paired]="hasPairedDevice && !isBlueMode">
  <div class="navigation-info">
    @if (isTabletView) {
      <button
        class="toggler"
        [class.opened]="isOpened"
        nz-button
        nzShape="circle"
        [nzSize]="'large'"
        [nzType]="isBlueMode ? 'default' : 'primary'"
        (click)="onToggleSideMenu()"
        [disabled]="togglerDisabled"
        [attr.data-cy]="'header-sidemenu__toggler'">
        <span
          nz-icon
          [nzType]="isOpened ? 'close' : 'menu'"
          nzTheme="outline"></span>
      </button>
    }
    <h3 class="page-title" [class.big]="isLarge">
      <ng-container
        *ngTemplateOutlet="
          pageTitleTemplate || defaultPageTitleTemplate
        "></ng-container>
      <ng-template #defaultPageTitleTemplate>{{ pageTitle }}</ng-template>
    </h3>
  </div>
  @if (isMiddleContent) {
    <ng-container
      *ngTemplateOutlet="
        customMiddleContent ?? defaultMiddleContent
      "></ng-container>
  }
  @if (isRightContent) {
    <ng-container
      *ngTemplateOutlet="
        customRightContent ?? defaultRightContent
      "></ng-container>
  }
  <div [class.offline]="!isOnline()" class="navigation-connection"></div>
</header>

<ng-template #defaultMiddleContent>
  <div class="page-info">
    <lib-alerts-list [alertListItems]="alertsItems" />
  </div>
</ng-template>

<ng-template #defaultRightContent>
  <div class="header-search" [style.min-width.px]="400">
    <nz-select
      [style.min-width.px]="400"
      #select
      [ngModel]="textSearch"
      (ngModelChange)="onModelChange($event)"
      (nzOnSearch)="onSearchPatient($event)"
      [nzPlaceHolder]="selectPlaceholder"
      [nzShowArrow]="false"
      nzServerSearch
      nzShowSearch
      [nzAutoFocus]="false"
      [nzAllowClear]="false"
      (nzFocus)="fieldFocus()"
      (nzBlur)="fieldBlur()"
      nzDropdownClassName="header-search__dropdown"
      [nzOptionHeightPx]="54">
      @for (patient of searchPatients; track patient.id) {
        <nz-option
          nzCustomContent
          [nzLabel]="patient.name"
          [nzValue]="patient.id">
          <div class="custom-content">
            @if (!patient?.avatar && !patient?.avatar?.signedUrl) {
              <nz-avatar
                nzSrc="assets/images/avatar.svg"
                class="aiomed-avatar"
                [nzSize]="30"></nz-avatar>
            } @else {
              <nz-avatar
                [nzSrc]="patient?.avatar?.signedUrl"
                [nzSize]="30"></nz-avatar>
            }
            <div class="content">
              <div class="content-top">
                <span class="content-top__name"
                  >{{ patient.lastName }}, {{ patient.firstName }}</span
                >
                <span class="content-top__info">
                  @if (patient.gender === 'FEMALE') {
                    <i
                      class="gender"
                      nz-icon
                      nzType="icons:female"
                      style="color: #ea61a9"></i>
                  }
                  @if (patient.gender === 'MALE') {
                    <i
                      class="gender"
                      nz-icon
                      nzType="icons:male"
                      style="color: #00bfff"></i>
                  }
                  @if (patient.dateOfBirth) {
                    {{ patient.dateOfBirth | age }}yrs
                  }
                </span>
              </div>
              <div class="content-bottom">
                <span class="content-bottom__room">{{ patient.room }}</span>
              </div>
            </div>
          </div>
        </nz-option>
      }
    </nz-select>
    <span class="search-btn" nz-icon nzType="search"></span>
  </div>
</ng-template>
