import { KeyValuePipe } from '@angular/common'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core'
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR
} from '@angular/forms'
import {
  NzInputNumberComponent,
  NzInputNumberModule
} from 'ng-zorro-antd/input-number'
import { EmrAlertItemErrorType } from '../emr-alert-item/emr-alert-item.component'
import { NzIconModule } from 'ng-zorro-antd/icon'

const isNumber = (value: any) => {
  return typeof value === 'number'
}

export const CUSTOM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputNumberComponent),
  multi: true
}

export interface MinMaxStep {
  nzMin: number
  nzMax: number
  // nzStep: number;
}

@Component({
  selector: 'lib-input-number',
  imports: [FormsModule, NzInputNumberModule, KeyValuePipe, NzIconModule],
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CUSTOM_CONTROL_VALUE_ACCESSOR],
  host: {
    '(keyup.enter)': 'enterPress($event)',
    '(keyup)': 'keyUp($event)'
  }
})
export class InputNumberComponent implements ControlValueAccessor {
  @ViewChildren(NzInputNumberComponent)
  numberInputs!: QueryList<NzInputNumberComponent>
  @Output() groupValueChange = new EventEmitter<{
    changedKey: string
    changedKeyNewValue: number
    newValue: { [key: string]: number }
  }>()
  @Output() onFocus = new EventEmitter<void>()
  @Output() onBlur = new EventEmitter<void>()
  @Input() hasError: boolean = false
  @Input() hasInputError: boolean = false
  @Input() hasErrorType: EmrAlertItemErrorType = 'manual'
  @Input() isCritical: boolean = false
  @Input() isDimmed: boolean = false
  @Input() showRemeasure: boolean = true
  @Input() isDisabled: boolean = false
  @Input() disabledEditing: boolean = false
  @Input() isLarge: boolean = true
  @Input() isOldValue: boolean = false
  @Input({
    transform: (v: MinMaxStep | { [key: string]: MinMaxStep }) => v
  })
  // minMaxStep?: MinMaxStep | { [key: string]: MinMaxStep }
  public value: number | { [key: string]: number } | undefined
  public isValueNumber: boolean = true
  public mainValue: any //number | { [key: string]: number } | undefined
  protected localShowRemeasure: boolean = true
  private currentFocusIndex: number = 0
  private isAutoJumpHappened: boolean = false
  private onChange!: (value: number | { [key: string]: number }) => void
  private onTouched!: () => void
  private cdr = inject(ChangeDetectorRef)

  @Input() set unit(unitValue: string) {
    if (!unitValue) return
    this.nzFormatter = (value: number | undefined): string =>
      value || value === 0 ? `${value}${unitValue}` : ''
    this.nzParser = (value: string): string => {
      unitValue.split('').forEach(u => {
        value = value.replace(/[^\w\.-]+/g, '').replace(`${u}`, '')
      })
      return value
    }
  }

  public nzFormatter: (value: number) => string | number = value => value

  public nzParser = (value: string): string =>
    value
      .trim()
      .replace(/。/g, '.')
      .replace(/[^\w\.-]+/g, '')

  public onInputValueChange(event: number, key?: string): void {
    if (
      !this.isValueNumber &&
      key &&
      this.value &&
      typeof this.value === 'object' &&
      this.mainValue &&
      typeof this.mainValue === 'object'
    ) {
      this.mainValue[key] = event
      this.groupValueChange.emit({
        changedKey: key,
        changedKeyNewValue: event,
        newValue: this.value
      })
      this.onChange(this.mainValue)
    } else {
      this.isValueNumber = true
      this.value = event
      this.mainValue = event
      this.onChange(event)
    }
  }

  public writeValue(value: number | { [key: string]: number }): void {
    this.value = value
    this.mainValue = typeof value === 'number' ? value : { ...value }
    this.isValueNumber = true
    if (!isNumber(value)) {
      this.isValueNumber = false
    }
    this.cdr.detectChanges()
  }

  public registerOnChange(
    fn: (value: number | { [key: string]: number }) => void
  ): void {
    this.onChange = fn
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  public trackBy(item: any, index: number): number {
    return index
  }

  public keepOrder(a: Record<string, any>, b: Record<string, any>) {
    return 1
  }

  public focus(): void {
    ;([...this.numberInputs][0] as NzInputNumberComponent).focus()
  }

  public setCurrentFocusIndex(index: number): void {
    this.currentFocusIndex = index
    this.onFocus.emit()
  }

  public removeRemeasure(): void {
    this.localShowRemeasure = false
    this.focus()
  }

  identity(foo: any): any {
    return foo
  }

  private enterPress(event: Event): void {
    if (this.numberInputs.length === 2 && this.currentFocusIndex === 0) {
      event.stopPropagation()
      ;([...this.numberInputs][1] as NzInputNumberComponent).focus()
      return
    }
  }

  private keyUp(event: KeyboardEvent): void {
    if (this.isAutoJumpHappened || this.currentFocusIndex !== 0) {
      return
    }

    if (
      typeof this.mainValue !== 'object' ||
      this.numberInputs.length !== 2 ||
      Object.values(this.mainValue)[0]?.toString().length !== 3
    ) {
      return
    }

    this.isAutoJumpHappened = true
    ;([...this.numberInputs][1] as NzInputNumberComponent).focus()
  }
}
