import { Pipe, PipeTransform } from '@angular/core'
import { transformCelsiusToFahrenheit } from '../../shared/utils/functions/functions'
import { MedicalSignAlertRuleDTO } from '../interfaces/medical-sign-alert-rule.interface'

@Pipe({
  name: 'medicalCriticalSign',
  standalone: true
})
export class MedicalCriticalSignPipe implements PipeTransform {
  transform(
    value: number,
    rules: MedicalSignAlertRuleDTO | any,
    type: string = 'default'
  ): boolean {
    if (!value || !rules) return false
    if (type !== 'default') {
      return (
        value > transformCelsiusToFahrenheit(rules.minCritical) ||
        value < transformCelsiusToFahrenheit(rules.maxCritical)
      )
    }
    return value > rules.minCritical || value < rules.maxCritical
  }
}
