<ul class="tabs">
  @for (tab of tabs; track tab.text; let index = $index) {
    <li
      class="tab"
      [class.tab-active]="tab.active"
      (click)="onTabClick(tab, index)">
      <ng-container
        *ngTemplateOutlet="
          titleTemplate || defaultTitleTemplate;
          context: { $implicit: tab }
        "></ng-container>
    </li>
  }
</ul>

<ng-template #defaultTitleTemplate let-tab>
  @if (tab.icon; as icon) {
    <span class="tab-icon" nz-icon [nzType]="icon"></span>
  }
  <span class="tab-text">{{ tab.text }}</span>
  @if (tab.badgeValue; as val) {
    <nz-badge
      [style.--_badge-bg-color]="tab.badgeBgColor"
      [nzCount]="val"
      nzStandalone></nz-badge>
  }
</ng-template>
