import { Pipe, PipeTransform } from '@angular/core'
import { DeviceCriticalStatus } from '../enums/device-critical-status'

@Pipe({
  name: 'deviceStatusIcon',
  standalone: true
})
export class DeviceStatusIconPipe implements PipeTransform {
  transform(data?: DeviceCriticalStatus): string {
    if (!data) return ''
    if (data === DeviceCriticalStatus.NoConnection) {
      return 'aio:not-connected'
    } else if (data === DeviceCriticalStatus.BadReading) {
      return 'aio:bad-reading'
    } else if (data === DeviceCriticalStatus.LowBattery) {
      return 'aio:low-battery'
    }
    return 'aio:no-data'
  }
}
