<section class="section devices">
  <h3 class="section-title">{{ title }}</h3>
  <div class="list-items">
    @if (criticalDevices.length) {
      @for (device of criticalDevices; track device.id) {
        <lib-critical-devices-card
          [room]="device?.patient?.room || ''"
          [patientName]="device?.patient?.name || ''"
          [statusText]="device.statusDetailInformation"
          [serialNumber]="device.serialNumber"></lib-critical-devices-card>
      }
    } @else {
      <div class="empty">
        <nz-empty nzNotFoundImage="simple"></nz-empty>
      </div>
    }
    <ng-template #empty>
      <div class="empty">
        <nz-empty nzNotFoundImage="simple"></nz-empty>
      </div>
    </ng-template>
  </div>
</section>
