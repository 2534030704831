import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
  inject
} from '@angular/core'
import { Subject, takeUntil } from 'rxjs'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { NzIconModule } from 'ng-zorro-antd/icon'
import {
  LibNotificationOptionsWithType,
  LibNotificationService
} from './lib-notification.service'
import { ThemeType } from '@ant-design/icons-angular'

const ICONS_MAP = new Map([
  ['success', { icon: 'check-circle', theme: 'fill' as ThemeType }],
  ['warning', { icon: 'info-circle', theme: 'fill' as ThemeType }],
  ['error', { icon: 'warning', theme: 'fill' as ThemeType }]
])

@Component({
  selector: 'lib-notification',
  imports: [NzIconModule],
  templateUrl: './lib-notification.component.html',
  styleUrls: ['./lib-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibNotificationComponent implements OnDestroy {
  private nzNotificationService = inject(NzNotificationService)
  private libNotificationService = inject(LibNotificationService)
  @ViewChild('template') template: TemplateRef<{}>

  private destroy$ = new Subject<void>()
  public iconsMap = ICONS_MAP

  constructor() {
    this.libNotificationService.notificationData$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.createBasicNotification(this.template, {
          title: data[1].title,
          duration: data[1].duration,
          type: data[0],
          description: data[1].description
        })
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private createBasicNotification(
    template: TemplateRef<{}>,
    data: LibNotificationOptionsWithType
  ): void {
    this.nzNotificationService.template(template, {
      nzDuration: data.duration,
      nzData: data,
      nzClass: data.type
    })
  }
}
