import { BaseTab } from '../interfaces/base-tab.interface'

export function setInitialActiveTab(tabs: BaseTab[]): BaseTab[] {
  const tabsActiveIndex = tabs.findIndex(tab => tab.active)
  return tabs.map((tab, index) => {
    tab.active = false
    if (index === Math.max(tabsActiveIndex, 0)) tab.active = true
    return tab
  })
}
