import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core'
import { NzEmptyModule } from 'ng-zorro-antd/empty'
import { CriticalPatientsCardComponent } from '../critical-patients-card/critical-patients-card.component'
import { CriticalPatient } from '../interfaces/critical-patient.interface'

@Component({
  selector: 'lib-critical-patients-list',
  imports: [CriticalPatientsCardComponent, NzEmptyModule],
  templateUrl: './critical-patients-list.component.html',
  styleUrls: ['./critical-patients-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CriticalPatientsListComponent {
  @Input() title: string = 'ALERTED PATIENTS'
  @Input({ required: true }) criticalPatients: CriticalPatient[] = []
  @Output() handlePatient: EventEmitter<CriticalPatient> =
    new EventEmitter<CriticalPatient>()

  public onHandlePatient(patient: CriticalPatient): void {
    this.handlePatient.emit(patient)
  }
}
