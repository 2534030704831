<ng-template #template let-data="data">
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span
        class="ant-notification-notice-icon"
        [class.success]="data.type === 'success'"
        [class.warning]="data.type === 'warning'"
        [class.error]="data.type === 'error'">
        @if (iconsMap.get(data.type); as icon) {
          <span nz-icon [nzType]="icon.icon" [nzTheme]="icon.theme"></span>
        }
      </span>
      <div class="ant-notification-notice-message">{{ data.title }}</div>
      @if (data.description) {
        <div class="ant-notification-notice-description">
          {{ data.description }}
        </div>
      }
    </div>
  </div>
</ng-template>
