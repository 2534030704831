import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core'
import { NgTemplateOutlet } from '@angular/common'
import { NzIconModule } from 'ng-zorro-antd/icon'
import { NzBadgeModule } from 'ng-zorro-antd/badge'
import { BaseTab } from './interfaces/base-tab.interface'
import { setInitialActiveTab } from './utils/tabs-transform'

@Component({
  selector: 'lib-tabs',
  imports: [NgTemplateOutlet, NzIconModule, NzBadgeModule],
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent<T extends BaseTab = BaseTab> {
  @Output() tabClick: EventEmitter<{ tab: T; index: number }> =
    new EventEmitter<{ tab: T; index: number }>()
  @Input() titleTemplate?: TemplateRef<any>
  @Input({ required: true, transform: setInitialActiveTab }) tabs: T[]

  public onTabClick(tab: T, index: number): void {
    this.tabs = this.tabs.map(tab => ({ ...tab, active: false }))
    this.tabs[index].active = true
    this.tabClick.emit({ tab, index })
  }
}
