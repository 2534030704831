import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { NzBadgeModule } from 'ng-zorro-antd/badge'
import { ShortNamePipe } from '../../shared/pipes/short-name.pipe'
import { AlertSubject } from '../enums/alert-subject.enum'
import { AlertSubjectIconPipe } from '../pipes/alert-subject-icon.pipe'
import { IconComponent } from '../../icon/icon.component'
import { AlertSeverity } from '../../shared/enums/alert-severity.enum'

@Component({
  selector: 'lib-critical-patients-card',
  imports: [NzBadgeModule, IconComponent, ShortNamePipe, AlertSubjectIconPipe],
  templateUrl: './critical-patients-card.component.html',
  styleUrls: ['./critical-patients-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CriticalPatientsCardComponent {
  @Input() patientName: string = ''
  @Input() patientRoom: string = ''
  @Input() patientAvatarUrl?: string
  @Input() patientAlerts: number = 0
  @Input() patientAlertsSubject: AlertSubject = AlertSubject.BloodGlucose
  @Input() severity: AlertSeverity = AlertSeverity.Critical
}
