import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'celsiusToFahrenheit',
  standalone: true
})
export class CelsiusToFahrenheitPipe implements PipeTransform {
  transform(data: number): number {
    if (!data) return 0
    return Number(((data * 9) / 5 + 32).toFixed(1))
    // return Number(
    // 	getUserLocale() === 'en-US'
    // 		? ((data * 9) / 5 + 32).toFixed(1)
    // 		: data.toFixed(1)
    // )
  }
}
