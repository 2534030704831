<section class="section patients">
  <h3 class="section-title">{{ title }}</h3>
  <div class="list-items">
    @if (criticalPatients.length) {
      @for (patient of criticalPatients; track patient.id) {
        <lib-critical-patients-card
          (click)="onHandlePatient(patient)"
          [patientAlerts]="patient.alerts.length"
          [patientAlertsSubject]="patient.alerts[0].subject"
          [patientAvatarUrl]="patient.avatar?.signedUrl"
          [patientName]="patient.name"
          [patientRoom]="patient.room" />
      }
    } @else {
      <div class="empty">
        <nz-empty nzNotFoundImage="simple"></nz-empty>
      </div>
    }
    <ng-template #empty>
      <div class="empty">
        <nz-empty nzNotFoundImage="simple"></nz-empty>
      </div>
    </ng-template>
  </div>
</section>
